export const definitions = {
  'AttachmentsFilters': {
    'type': 'object',
    'required': ['emailId', 'targetObject'],
    'properties': {
      'emailId': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'AttachmentsFilters'
  },
  'AwsCredentialValues': {
    'type': 'object',
    'required': ['accessKeyId', 'region', 'secretKey'],
    'properties': {
      'accessKeyId': {
        'type': 'string'
      },
      'region': {
        'type': 'string'
      },
      's3Bucket': {
        'type': 'string'
      },
      'secretKey': {
        'type': 'string'
      }
    },
    'title': 'AwsCredentialValues'
  },
  'CheckEmailAvailabilityUserStepFields': {
    'type': 'object',
    'required': ['email', 'targetObject'],
    'properties': {
      'email': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'CheckEmailAvailabilityUserStepFields'
  },
  'CompressZipFields': {
    'type': 'object',
    'properties': {
      'entries': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ZipElement'
        }
      }
    },
    'title': 'CompressZipFields'
  },
  'ConvertImageStepFields': {
    'type': 'object',
    'required': ['type'],
    'properties': {
      'source': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['BMP', 'GIF', 'JPEG', 'PNG']
      }
    },
    'title': 'ConvertImageStepFields'
  },
  'CreateCaptcha': {
    'type': 'object',
    'properties': {
      'image': {
        'type': 'string'
      },
      'key': {
        'type': 'string'
      }
    },
    'title': 'CreateCaptcha'
  },
  'CreateCredentialFields': {
    'type': 'object',
    'required': ['name', 'targetObject', 'type', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'type': {
        'type': 'string',
        'enum': ['AWS', 'FLOWY', 'IMAP', 'JDBC', 'JMS', 'JWT', 'KAFKA', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PLUGIN', 'RABBITMQ', 'REST', 'SCRIPT', 'SECURITY', 'SLACK', 'SMTP', 'TWILIO']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'CreateCredentialFields'
  },
  'CreatePdfRequest': {
    'type': 'object',
    'required': ['templateBody', 'type'],
    'properties': {
      'templateBody': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['HANDLEBARS', 'MUSTACHE', 'THYMELEAF', 'VELOCITY']
      },
      'variables': {
        'type': 'object'
      }
    },
    'title': 'CreatePdfRequest'
  },
  'CreateS3StepFields': {
    'type': 'object',
    'required': ['content', 'key'],
    'properties': {
      'content': {
        'type': 'string'
      },
      'key': {
        'type': 'string'
      },
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'CreateS3StepFields'
  },
  'CreateUserStepFields': {
    'type': 'object',
    'required': ['email', 'name', 'targetObject'],
    'properties': {
      'email': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'CreateUserStepFields'
  },
  'CredentialFieldEncryptionStatus': {
    'type': 'object',
    'required': ['field', 'type'],
    'properties': {
      'field': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['ENCRYPTED', 'PLAIN']
      }
    },
    'title': 'CredentialFieldEncryptionStatus'
  },
  'CredentialFieldToEncrypt': {
    'type': 'object',
    'required': ['field', 'type'],
    'properties': {
      'field': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['ENCRYPT', 'ENCRYPTED', 'PLAIN']
      }
    },
    'title': 'CredentialFieldToEncrypt'
  },
  'CredentialFields': {
    'type': 'object',
    'required': ['name', 'type', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['AWS', 'FLOWY', 'IMAP', 'JDBC', 'JMS', 'JWT', 'KAFKA', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PLUGIN', 'RABBITMQ', 'REST', 'SCRIPT', 'SECURITY', 'SLACK', 'SMTP', 'TWILIO']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'CredentialFields'
  },
  'CredentialNameFields': {
    'type': 'object',
    'required': ['queryName'],
    'properties': {
      'queryName': {
        'type': 'string'
      }
    },
    'title': 'CredentialNameFields'
  },
  'CredentialProperties': {
    'type': 'object',
    'required': ['action'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'DISABLE', 'ENABLE', 'UPDATE']
      },
      'fields': {
        'type': 'object',
        'description': 'CREATE: CreateCredentialFields, UPDATE: UpdateCredentialFields, DISABLE: CredentialIdFields, ENABLE: CredentialIdFields'
      }
    },
    'title': 'CredentialProperties'
  },
  'CrossReferencePayment': {
    'type': 'object',
    'required': ['crossReference'],
    'properties': {
      'crossReference': {
        'type': 'string'
      },
      'cv2': {
        'type': 'string'
      }
    },
    'title': 'CrossReferencePayment'
  },
  'CsvStepProperties': {
    'type': 'object',
    'required': ['source', 'targetObject'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CSV_TO_JSON', 'JSON_TO_CSV']
      },
      'customDelimiter': {
        'type': 'string'
      },
      'delimiter': {
        'type': 'string',
        'enum': ['COMMA', 'CUSTOM', 'PIPE', 'SEMICOLON', 'SPACE', 'TAB']
      },
      'filters': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'includeHeaders': {
        'type': 'boolean'
      },
      'qualifier': {
        'type': 'string',
        'enum': ['DOUBLE_QUOTES', 'NONE', 'SINGLE_QUOTES']
      },
      'source': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'CsvStepProperties'
  },
  'DecimalFormatFieldsReq': {
    'type': 'object',
    'properties': {
      'locale': {
        '$ref': '#/definitions/LocaleReq'
      },
      'pattern': {
        'type': 'string'
      }
    },
    'title': 'DecimalFormatFieldsReq'
  },
  'DecimalFormatFieldsRes': {
    'type': 'object',
    'properties': {
      'locale': {
        '$ref': '#/definitions/LocaleRes'
      },
      'pattern': {
        'type': 'string'
      }
    },
    'title': 'DecimalFormatFieldsRes'
  },
  'DecompressZipFields': {
    'type': 'object',
    'required': ['sourceObject'],
    'properties': {
      'sourceObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'DecompressZipFields'
  },
  'DeleteResourceResult': {
    'type': 'object',
    'properties': {
      'usages': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceIdName'
        }
      }
    },
    'title': 'DeleteResourceResult'
  },
  'DeleteRoleResult': {
    'type': 'object',
    'properties': {
      'relatedResources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceIdNameProjection'
        }
      },
      'relatedUsers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/IdNameProjection'
        }
      }
    },
    'title': 'DeleteRoleResult'
  },
  'DeleteS3StepFields': {
    'type': 'object',
    'required': ['key'],
    'properties': {
      'key': {
        'type': 'string'
      },
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'DeleteS3StepFields'
  },
  'EmailIdFilters': {
    'type': 'object',
    'required': ['emailId'],
    'properties': {
      'emailId': {
        'type': 'string'
      }
    },
    'title': 'EmailIdFilters'
  },
  'EmailStepAttachment': {
    'type': 'object',
    'required': ['content', 'contentType', 'name'],
    'properties': {
      'content': {
        'type': 'string'
      },
      'contentType': {
        'type': 'string',
        'enum': ['APPLICATION_JSON', 'APPLICATION_PDF', 'APPLICATION_XML', 'APPLICATION_ZIP', 'IMAGE_GIF', 'IMAGE_JPEG', 'IMAGE_PNG', 'IMAGE_SVG', 'TEXT_CSV', 'TEXT_PLAIN']
      },
      'name': {
        'type': 'string'
      }
    },
    'title': 'EmailStepAttachment'
  },
  'EmailStepProperties': {
    'type': 'object',
    'required': ['credentialName', 'textTemplate', 'to', 'variables'],
    'properties': {
      'attachments': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EmailStepAttachment'
        }
      },
      'bcc': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'cc': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'credentialName': {
        'type': 'string'
      },
      'from': {
        'type': 'string'
      },
      'locale': {
        'type': 'string',
        'minLength': 2,
        'maxLength': 2
      },
      'textTemplate': {
        'type': 'string'
      },
      'to': {
        'type': 'string'
      },
      'variables': {
        'type': 'object'
      }
    },
    'title': 'EmailStepProperties'
  },
  'EncoderStepProperties': {
    'type': 'object',
    'required': ['action'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['ENCODE_HTML_SPECIAL_CHARS', 'URL_DECODE', 'URL_ENCODE']
      },
      'converts': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SourceTarget'
        }
      }
    },
    'title': 'EncoderStepProperties'
  },
  'Event': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processingStartOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'status': {
        'type': 'string',
        'enum': ['FAILED', 'IN_PROGRESS', 'NEW', 'ON_HOLD', 'PROCESS_INACTIVE', 'SUCCESSFUL', 'TIMEOUT']
      },
      'threadId': {
        'type': 'integer',
        'format': 'int64'
      },
      'triggerId': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['CRON', 'MESSAGING', 'REST', 'RUN_PROCESS']
      }
    },
    'title': 'Event'
  },
  'EventFull': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'exceptionMessage': {
        'type': 'string'
      },
      'exceptionStackTrace': {
        'type': 'string'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'input': {
        'type': 'object'
      },
      'instanceId': {
        'type': 'string'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'output': {
        'type': 'object'
      },
      'parentEventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'parentProcessId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processingStartOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'receivingInstanceId': {
        'type': 'string'
      },
      'respondSent': {
        'type': 'boolean'
      },
      'status': {
        'type': 'string',
        'enum': ['FAILED', 'IN_PROGRESS', 'NEW', 'ON_HOLD', 'PROCESS_INACTIVE', 'SUCCESSFUL', 'TIMEOUT']
      },
      'threadId': {
        'type': 'integer',
        'format': 'int64'
      },
      'triggerId': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['CRON', 'MESSAGING', 'REST', 'RUN_PROCESS']
      }
    },
    'title': 'EventFull'
  },
  'EventIdResponse': {
    'type': 'object',
    'properties': {
      'eventId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'EventIdResponse'
  },
  'EventStatusCount': {
    'type': 'object',
    'properties': {
      'count': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['FAILED', 'IN_PROGRESS', 'NEW', 'ON_HOLD', 'PROCESS_INACTIVE', 'SUCCESSFUL', 'TIMEOUT']
      }
    },
    'title': 'EventStatusCount'
  },
  'ExceptionCatch': {
    'type': 'object',
    'properties': {
      'exceptions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'name': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Step«StepProperties»'
        }
      }
    },
    'title': 'ExceptionCatch'
  },
  'ExecuteExternalCommandStepProperties': {
    'type': 'object',
    'required': ['command'],
    'properties': {
      'command': {
        'type': 'string'
      },
      'input': {
        'type': 'string'
      },
      'resultCode': {
        'type': 'string'
      },
      'resultErrors': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'targetObject': {
        'type': 'string'
      }
    },
    'title': 'ExecuteExternalCommandStepProperties'
  },
  'ExecuteJdbcScriptRequest': {
    'type': 'object',
    'properties': {
      'script': {
        'type': 'string'
      }
    },
    'title': 'ExecuteJdbcScriptRequest'
  },
  'ExecuteProcessStepProperties': {
    'type': 'object',
    'required': ['input', 'processName'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['ASYNC', 'INLINE', 'SYNC']
      },
      'childEventIdPath': {
        'type': 'string'
      },
      'input': {
        'type': 'object'
      },
      'output': {
        'type': 'object'
      },
      'processName': {
        'type': 'string'
      }
    },
    'title': 'ExecuteProcessStepProperties'
  },
  'ExecuteScriptStepProperties': {
    'type': 'object',
    'required': ['set'],
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'librariesNames': {
        'type': 'array',
        'uniqueItems': true,
        'items': {
          'type': 'string'
        }
      },
      'set': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    },
    'title': 'ExecuteScriptStepProperties'
  },
  'FlowyCredentialValues': {
    'type': 'object',
    'required': ['apiKey', 'url', 'username'],
    'properties': {
      'apiKey': {
        'type': 'string'
      },
      'url': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      }
    },
    'title': 'FlowyCredentialValues'
  },
  'FlowyModule': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'loadCredentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['DOWNLOAD', 'UPLOAD']
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'remoteId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceId'
        }
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      }
    },
    'title': 'FlowyModule'
  },
  'FlowyProcessFull': {
    'type': 'object',
    'properties': {
      'comment': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'errorsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isCacheStepLogsEnabled': {
        'type': 'boolean'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isStepLogsEnabled': {
        'type': 'boolean'
      },
      'logsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'maxProcessingDurationInMSec': {
        'type': 'integer',
        'format': 'int32'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'overallSimultaneousExecutions': {
        'type': 'integer',
        'format': 'int32'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'simultaneousExecutionsPerInstance': {
        'type': 'integer',
        'format': 'int32'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'steps': {
        '$ref': '#/definitions/ProcessSteps'
      },
      'validationRuleId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'FlowyProcessFull'
  },
  'ForeachStepProperties': {
    'type': 'object',
    'required': ['query', 'recordName', 'steps'],
    'properties': {
      'query': {
        'type': 'string'
      },
      'recordName': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Step«StepProperties»'
        }
      }
    },
    'title': 'ForeachStepProperties'
  },
  'ForgotPasswordRequest': {
    'type': 'object',
    'required': ['captchaAnswer', 'captchaKey', 'email'],
    'properties': {
      'captchaAnswer': {
        'type': 'string'
      },
      'captchaKey': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      }
    },
    'title': 'ForgotPasswordRequest'
  },
  'FormattingStepProperties': {
    'type': 'object',
    'required': ['action'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['DECIMAL_FORMAT']
      },
      'converts': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SourceTarget'
        }
      },
      'fields': {
        'type': 'object',
        'description': 'DECIMAL_FORMAT: DecimalFormatFields'
      }
    },
    'title': 'FormattingStepProperties'
  },
  'FromTemplateImageStepFields': {
    'type': 'object',
    'required': ['textTemplate', 'width'],
    'properties': {
      'height': {
        'type': 'integer',
        'format': 'int32'
      },
      'locale': {
        'type': 'string'
      },
      'textTemplate': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['BMP', 'GIF', 'JPEG', 'PNG']
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'width': {
        'type': 'integer',
        'format': 'int32'
      }
    },
    'title': 'FromTemplateImageStepFields'
  },
  'FrontendSetting': {
    'type': 'object',
    'required': ['baseUrlRest', 'setPasswordUrl'],
    'properties': {
      'baseUrlRest': {
        'type': 'string'
      },
      'defaultLocale': {
        'type': 'string',
        'minLength': 2,
        'maxLength': 2
      },
      'logServerUrl': {
        'type': 'string'
      },
      'metricServerUrl': {
        'type': 'string'
      },
      'setPasswordUrl': {
        'type': 'string'
      },
      'visualisationServerUrl': {
        'type': 'string'
      }
    },
    'title': 'FrontendSetting'
  },
  'GenerateApiKeyUserStepFields': {
    'type': 'object',
    'required': ['email', 'password', 'targetObject'],
    'properties': {
      'email': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'GenerateApiKeyUserStepFields'
  },
  'GetStorageStepFields': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'GetStorageStepFields'
  },
  'History': {
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'DELETE', 'EDIT']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'resource': {
        'type': 'object'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      },
      'userId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'History'
  },
  'HistoryDelete': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      },
      'userId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'HistoryDelete'
  },
  'HistoryFull': {
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'DELETE', 'EDIT']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'resource': {
        'type': 'object'
      },
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      },
      'userId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'HistoryFull'
  },
  'HubReviewCreateRequest': {
    'type': 'object',
    'required': ['body', 'credentialId', 'remoteModuleId', 'title'],
    'properties': {
      'body': {
        'type': 'string'
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'rating': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'maximum': 5,
        'exclusiveMinimum': false,
        'exclusiveMaximum': false
      },
      'remoteModuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'title': {
        'type': 'string'
      }
    },
    'title': 'HubReviewCreateRequest'
  },
  'IdNameProjection': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      }
    },
    'title': 'IdNameProjection'
  },
  'ImageStepProperties': {
    'type': 'object',
    'required': ['action', 'targetObject'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CONVERT', 'DOWNLOAD', 'FROM_TEMPLATE', 'INFO', 'RESCALE']
      },
      'fields': {
        'type': 'object',
        'description': 'CONVERT: ConvertImageStepFields, RESCALE: RescaleImageStepFields, FROM_TEMPLATE: FromTemplateImageStepFields, INFO: SourceImageStepFields, DOWNLOAD: SourceImageStepFields'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ImageStepProperties'
  },
  'ImapCredentialValues': {
    'type': 'object',
    'required': ['host', 'password', 'port', 'username'],
    'properties': {
      'host': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'username': {
        'type': 'string'
      }
    },
    'title': 'ImapCredentialValues'
  },
  'ImapStepProperties': {
    'type': 'object',
    'required': ['action', 'credentialName'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['ATTACHMENTS', 'DELETE', 'MARK_READ', 'MARK_UNREAD', 'SEARCH']
      },
      'credentialName': {
        'type': 'string'
      },
      'fields': {
        'type': 'object'
      },
      'folder': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      }
    },
    'title': 'ImapStepProperties'
  },
  'Instance': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'threadsStats': {
        'type': 'object'
      },
      'type': {
        'type': 'string',
        'enum': ['ADMIN', 'PROCESSING']
      }
    },
    'title': 'Instance'
  },
  'InstanceProcessingSetting': {
    'type': 'object',
    'properties': {
      'maxProcessingThreads': {
        'type': 'integer',
        'format': 'int32'
      },
      'maxTriggerHttpProcessingInMSec': {
        'type': 'integer',
        'format': 'int32',
        'maximum': 3600000,
        'exclusiveMaximum': false
      },
      'maxTriggerHttpThreads': {
        'type': 'integer',
        'format': 'int32'
      }
    },
    'title': 'InstanceProcessingSetting'
  },
  'InternalJob': {
    'type': 'object',
    'properties': {
      'finished': {
        'type': 'string',
        'format': 'date-time'
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'started': {
        'type': 'string',
        'format': 'date-time'
      },
      'status': {
        'type': 'string',
        'enum': ['FINISHED', 'IN_PROGRESS']
      },
      'type': {
        'type': 'string',
        'enum': ['CLEAN_CAPTCHA', 'CLEAN_HISTORY', 'CLEAN_STORAGE', 'CLEAN_UP', 'CLEAN_UP_ERRORS', 'CREDENTIALS_SYNC', 'CRON_TRIGGERS_SYNC', 'FIX_EXECUTION_STATE', 'INSTANCE_HEARTBEAT', 'LIBRARIES_SYNC', 'MESSAGING_TRIGGER_SYNC', 'PLUGINS_SYNC', 'PROCESS_EVENTS', 'REST_TRIGGER_SYNC', 'SETTINGS_SYNC']
      }
    },
    'title': 'InternalJob'
  },
  'JdbcAdminOptions': {
    'type': 'object',
    'properties': {
      'queryConsole': {
        'type': 'boolean'
      }
    },
    'title': 'JdbcAdminOptions'
  },
  'JdbcCredentialValues': {
    'type': 'object',
    'required': ['password', 'url', 'username'],
    'properties': {
      'connectionTimeout': {
        'type': 'integer',
        'format': 'int32'
      },
      'maxPoolSize': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'exclusiveMinimum': false
      },
      'password': {
        'type': 'string'
      },
      'url': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      }
    },
    'title': 'JdbcCredentialValues'
  },
  'JdbcExecuteScriptResult': {
    'type': 'object',
    'properties': {
      'affectedRows': {
        'type': 'integer',
        'format': 'int32'
      },
      'error': {
        'type': 'string'
      },
      'isSuccess': {
        'type': 'boolean'
      },
      'result': {
        'type': 'object'
      }
    },
    'title': 'JdbcExecuteScriptResult'
  },
  'JdbcStepProperties': {
    'type': 'object',
    'required': ['action', 'credentialName', 'query'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CUSTOM', 'DELETE', 'INSERT', 'SELECT', 'UPDATE']
      },
      'credentialName': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      },
      'singleResult': {
        'type': 'boolean'
      },
      'targetObject': {
        'type': 'string'
      }
    },
    'title': 'JdbcStepProperties'
  },
  'JmsCredentialValues': {
    'type': 'object',
    'required': ['destinationName', 'destinationType', 'uri'],
    'properties': {
      'destinationName': {
        'type': 'string'
      },
      'destinationType': {
        'type': 'string',
        'enum': ['QUEUE', 'TOPIC']
      },
      'password': {
        'type': 'string'
      },
      'uri': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      }
    },
    'title': 'JmsCredentialValues'
  },
  'JmsMessagingStepConfig': {
    'type': 'object',
    'properties': {
      'deliveryMode': {
        'type': 'string',
        'enum': ['NON_PERSISTENT', 'PERSISTENT']
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'timeToLive': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'JmsMessagingStepConfig'
  },
  'JwtCredentialValues': {
    'type': 'object',
    'required': ['keyType', 'privateKey'],
    'properties': {
      'keyType': {
        'type': 'string'
      },
      'payload': {
        'type': 'object'
      },
      'privateKey': {
        'type': 'string'
      },
      'signatureAlgorithm': {
        'type': 'string',
        'enum': ['ES256', 'ES384', 'ES512', 'HS256', 'HS384', 'HS512', 'NONE', 'PS256', 'PS384', 'PS512', 'RS256', 'RS384', 'RS512']
      }
    },
    'title': 'JwtCredentialValues'
  },
  'JwtStepProperties': {
    'type': 'object',
    'required': ['credentialName', 'targetObject'],
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'payload': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'JwtStepProperties'
  },
  'KafkaCredentialValues': {
    'type': 'object',
    'required': ['bootstrapServers', 'topic'],
    'properties': {
      'bootstrapServers': {
        'type': 'string'
      },
      'groupId': {
        'type': 'string'
      },
      'topic': {
        'type': 'string'
      }
    },
    'title': 'KafkaCredentialValues'
  },
  'KafkaMessagingStepConfig': {
    'type': 'object',
    'required': ['messageKey'],
    'properties': {
      'messageKey': {
        'type': 'string'
      }
    },
    'title': 'KafkaMessagingStepConfig'
  },
  'Library': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      }
    },
    'title': 'Library'
  },
  'LibraryExport': {
    'type': 'object',
    'properties': {
      'artifact': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      }
    },
    'title': 'LibraryExport'
  },
  'LibraryRequest': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'type'],
    'properties': {
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      }
    },
    'title': 'LibraryRequest'
  },
  'ListS3StepFields': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ListS3StepFields'
  },
  'LocaleReq': {
    'type': 'object',
    'title': 'LocaleReq'
  },
  'LocaleRes': {
    'type': 'object',
    'properties': {
      'country': {
        'type': 'string'
      },
      'displayCountry': {
        'type': 'string'
      },
      'displayLanguage': {
        'type': 'string'
      },
      'displayName': {
        'type': 'string'
      },
      'displayScript': {
        'type': 'string'
      },
      'displayVariant': {
        'type': 'string'
      },
      'extensionKeys': {
        'type': 'array',
        'uniqueItems': true,
        'items': {
          'type': 'string'
        }
      },
      'iso3Country': {
        'type': 'string'
      },
      'iso3Language': {
        'type': 'string'
      },
      'language': {
        'type': 'string'
      },
      'script': {
        'type': 'string'
      },
      'unicodeLocaleAttributes': {
        'type': 'array',
        'uniqueItems': true,
        'items': {
          'type': 'string'
        }
      },
      'unicodeLocaleKeys': {
        'type': 'array',
        'uniqueItems': true,
        'items': {
          'type': 'string'
        }
      },
      'variant': {
        'type': 'string'
      }
    },
    'title': 'LocaleRes'
  },
  'LogStepProperties': {
    'type': 'object',
    'properties': {
      'message': {
        'type': 'string'
      }
    },
    'title': 'LogStepProperties'
  },
  'LoginRequest': {
    'type': 'object',
    'required': ['email', 'password'],
    'properties': {
      'email': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      }
    },
    'title': 'LoginRequest'
  },
  'MessagingStepProperties': {
    'type': 'object',
    'required': ['credentialName', 'message', 'service'],
    'properties': {
      'config': {
        'type': 'object'
      },
      'credentialName': {
        'type': 'string'
      },
      'message': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'service': {
        'type': 'string',
        'enum': ['AWS_SQS', 'JMS', 'KAFKA', 'RABBITMQ']
      }
    },
    'title': 'MessagingStepProperties'
  },
  'Meta': {
    'type': 'object',
    'properties': {
      'pageNumber': {
        'type': 'integer',
        'format': 'int32'
      },
      'totalItems': {
        'type': 'integer',
        'format': 'int64'
      },
      'totalPages': {
        'type': 'integer',
        'format': 'int32'
      }
    },
    'title': 'Meta'
  },
  'ModuleDescription': {
    'type': 'object',
    'required': ['description', 'language'],
    'properties': {
      'description': {
        'type': 'string'
      },
      'language': {
        'type': 'string'
      }
    },
    'title': 'ModuleDescription'
  },
  'ModuleExport': {
    'type': 'object',
    'properties': {
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'loadCredentialName': {
        'type': 'string'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['DOWNLOAD', 'UPLOAD']
      },
      'name': {
        'type': 'string'
      },
      'remoteId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceName'
        }
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'version': {
        'type': 'string'
      }
    },
    'title': 'ModuleExport'
  },
  'ModuleExportResult': {
    'type': 'object',
    'properties': {
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'name': {
        'type': 'string'
      },
      'notExported': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceId'
        }
      },
      'resources': {
        '$ref': '#/definitions/ResourcesExport'
      },
      'version': {
        'type': 'string'
      }
    },
    'title': 'ModuleExportResult'
  },
  'ModuleRequest': {
    'type': 'object',
    'required': ['name', 'resources', 'roles'],
    'properties': {
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'loadCredentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['DOWNLOAD', 'UPLOAD']
      },
      'name': {
        'type': 'string'
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceId'
        }
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    },
    'title': 'ModuleRequest'
  },
  'MongodbCredentialValues': {
    'type': 'object',
    'required': ['url'],
    'properties': {
      'clientKey': {
        'type': 'string'
      },
      'url': {
        'type': 'string'
      }
    },
    'title': 'MongodbCredentialValues'
  },
  'MongodbStepProperties': {
    'type': 'object',
    'required': ['action', 'collectionName', 'credentialName'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['DELETE', 'FIND', 'INSERT', 'UPDATE']
      },
      'collectionName': {
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'projection': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      },
      'queryUpdate': {
        'type': 'string'
      },
      'singleResult': {
        'type': 'boolean'
      },
      'targetObject': {
        'type': 'string'
      }
    },
    'title': 'MongodbStepProperties'
  },
  'NamedSteps': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Step«StepProperties»'
        }
      }
    },
    'title': 'NamedSteps'
  },
  'PageResponse«Event»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Event'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Event»'
  },
  'PageResponse«FlowyModule»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/FlowyModule'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«FlowyModule»'
  },
  'PageResponse«HistoryDelete»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/HistoryDelete'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«HistoryDelete»'
  },
  'PageResponse«History»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/History'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«History»'
  },
  'PageResponse«Instance»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Instance'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Instance»'
  },
  'PageResponse«InternalJob»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/InternalJob'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«InternalJob»'
  },
  'PageResponse«Library»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Library'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Library»'
  },
  'PageResponse«Plugin»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Plugin'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Plugin»'
  },
  'PageResponse«ProcessCredential»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessCredential'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«ProcessCredential»'
  },
  'PageResponse«ProcessExecution»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessExecution'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«ProcessExecution»'
  },
  'PageResponse«ProcessStepFailure»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessStepFailure'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«ProcessStepFailure»'
  },
  'PageResponse«ProcessStepLog»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessStepLog'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«ProcessStepLog»'
  },
  'PageResponse«ResourceStatus»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceStatus'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«ResourceStatus»'
  },
  'PageResponse«Role»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Role»'
  },
  'PageResponse«Setting»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Setting'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Setting»'
  },
  'PageResponse«Telemetry»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Telemetry'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«Telemetry»'
  },
  'PageResponse«TextTemplate»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTemplate'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«TextTemplate»'
  },
  'PageResponse«TextTranslation»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTranslation'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«TextTranslation»'
  },
  'PageResponse«TriggerCron»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerCron'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«TriggerCron»'
  },
  'PageResponse«TriggerMessaging»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerMessaging'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«TriggerMessaging»'
  },
  'PageResponse«TriggerRest»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerRest'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«TriggerRest»'
  },
  'PageResponse«User»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/User'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«User»'
  },
  'PageResponse«ValidationRule»': {
    'type': 'object',
    'properties': {
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ValidationRule'
        }
      },
      'meta': {
        '$ref': '#/definitions/Meta'
      }
    },
    'title': 'PageResponse«ValidationRule»'
  },
  'PaymentSenseConnectEAccessTokenParameters': {
    'type': 'object',
    'required': ['currencyCode', 'merchantUrl', 'orderId', 'transactionType'],
    'properties': {
      'amount': {
        'type': 'integer',
        'format': 'int32'
      },
      'crossReference': {
        'type': 'string'
      },
      'currencyCode': {
        'type': 'string',
        'pattern': '\\d\\d\\d'
      },
      'customerId': {
        'type': 'string'
      },
      'merchantTransactionId': {
        'type': 'string'
      },
      'merchantUrl': {
        'type': 'string'
      },
      'metaData': {
        'type': 'object'
      },
      'newTransaction': {
        'type': 'boolean'
      },
      'orderDescription': {
        'type': 'string'
      },
      'orderId': {
        'type': 'string'
      },
      'shippingDetails': {
        '$ref': '#/definitions/ShippingDetails'
      },
      'transactionSource': {
        'type': 'string',
        'enum': ['MOTO']
      },
      'transactionType': {
        'type': 'string',
        'enum': ['COLLECTION', 'PREAUTH', 'REFUND', 'SALE', 'VOID']
      },
      'userAddress1': {
        'type': 'string'
      },
      'userAddress2': {
        'type': 'string'
      },
      'userAddress3': {
        'type': 'string'
      },
      'userAddress4': {
        'type': 'string'
      },
      'userAgent': {
        'type': 'string'
      },
      'userCity': {
        'type': 'string'
      },
      'userCountryCode': {
        'type': 'string',
        'pattern': '\\d\\d\\d'
      },
      'userEmailAddress': {
        'type': 'string'
      },
      'userIpAddress': {
        'type': 'string'
      },
      'userPhoneNumber': {
        'type': 'string'
      },
      'userPostcode': {
        'type': 'string'
      },
      'userState': {
        'type': 'string'
      },
      'waitPreExecute': {
        'type': 'boolean'
      },
      'webHookUrl': {
        'type': 'string'
      }
    },
    'title': 'PaymentSenseConnectEAccessTokenParameters'
  },
  'PaymentSenseConnectECredentialValues': {
    'type': 'object',
    'required': ['hostUrl', 'jwt', 'merchantUrl'],
    'properties': {
      'hostUrl': {
        'type': 'string'
      },
      'jwt': {
        'type': 'string'
      },
      'merchantUrl': {
        'type': 'string'
      }
    },
    'title': 'PaymentSenseConnectECredentialValues'
  },
  'PaymentSenseConnectECrossReferenceParameters': {
    'type': 'object',
    'required': ['accessToken'],
    'properties': {
      'accessToken': {
        'type': 'string'
      },
      'crossReferencePayment': {
        '$ref': '#/definitions/CrossReferencePayment'
      }
    },
    'title': 'PaymentSenseConnectECrossReferenceParameters'
  },
  'PaymentSenseConnectEParameters': {
    'type': 'object',
    'required': ['accessToken'],
    'properties': {
      'accessToken': {
        'type': 'string'
      }
    },
    'title': 'PaymentSenseConnectEParameters'
  },
  'PaymentSenseConnectEProperties': {
    'type': 'object',
    'required': ['action', 'credentialName'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['ACCESS_TOKEN', 'CROSS_REFERENCE_PAYMENT', 'PAYMENT_DETAILS', 'PAYMENT_METHODS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN']
      },
      'credentialName': {
        'type': 'string'
      },
      'parameters': {
        'type': 'object',
        'description': 'ACCESS_TOKENS: PaymentSenseConnectEAccessTokenParameters, PAYMENT_DETAILS: PaymentSenseConnectEParameters, RESUME_PAYMENT: PaymentSenseConnectEParameters, CROSS_REFERENCE_PAYMENT: PaymentSenseConnectECrossReferenceParameters, REVOKE_ACCESS_TOKEN: PaymentSenseConnectEParameters, PAYMENT_METHODS: null'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'PaymentSenseConnectEProperties'
  },
  'PaymentSensePacCredentialValues': {
    'type': 'object',
    'required': ['apiKey', 'hostUrl', 'installerId', 'softwareHouseId', 'username'],
    'properties': {
      'apiKey': {
        'type': 'string'
      },
      'hostUrl': {
        'type': 'string'
      },
      'installerId': {
        'type': 'string'
      },
      'softwareHouseId': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      }
    },
    'title': 'PaymentSensePacCredentialValues'
  },
  'PaymentSensePacProperties': {
    'type': 'object',
    'required': ['action', 'credentialName'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CANCEL_TRANSACTION', 'GET_REPORT', 'GET_TERMINAL', 'GET_TERMINALS', 'GET_TRANSACTION', 'SIGNATURE', 'START_REPORT', 'START_TRANSACTION']
      },
      'credentialName': {
        'type': 'string'
      },
      'parameters': {
        'type': 'object',
        'description': 'GET_TERMINALS: null, GET_TERMINAL: PaymentSensePacTerminalIdParameters, START_TRANSACTION: PaymentSensePacStartTransactionParameters, GET_TRANSACTION: PaymentSensePacRequestIdParameters, SIGNATURE: PaymentSensePacSignatureParameters, START_REPORT: PaymentSensePacReportParameters, CANCEL_TRANSACTION: PaymentSensePacRequestIdParameters, GET_REPORT: PaymentSensePacRequestIdParameters'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'PaymentSensePacProperties'
  },
  'PaymentSensePacReportParameters': {
    'type': 'object',
    'required': ['reportType', 'terminalId'],
    'properties': {
      'reportType': {
        'type': 'string',
        'enum': ['BANKING', 'END_OF_DAY', 'X_BALANCE', 'Z_BALANCE']
      },
      'terminalId': {
        'type': 'string'
      }
    },
    'title': 'PaymentSensePacReportParameters'
  },
  'PaymentSensePacRequestIdParameters': {
    'type': 'object',
    'required': ['requestId', 'terminalId'],
    'properties': {
      'requestId': {
        'type': 'string'
      },
      'terminalId': {
        'type': 'string'
      }
    },
    'title': 'PaymentSensePacRequestIdParameters'
  },
  'PaymentSensePacSignatureParameters': {
    'type': 'object',
    'required': ['accepted', 'requestId', 'terminalId'],
    'properties': {
      'accepted': {
        'type': 'boolean'
      },
      'requestId': {
        'type': 'string'
      },
      'terminalId': {
        'type': 'string'
      }
    },
    'title': 'PaymentSensePacSignatureParameters'
  },
  'PaymentSensePacStartTransactionParameters': {
    'type': 'object',
    'required': ['requestId', 'terminalId', 'transaction'],
    'properties': {
      'requestId': {
        'type': 'string'
      },
      'terminalId': {
        'type': 'string'
      },
      'transaction': {
        '$ref': '#/definitions/StartTransaction'
      }
    },
    'title': 'PaymentSensePacStartTransactionParameters'
  },
  'PaymentSensePacTerminalIdParameters': {
    'type': 'object',
    'required': ['terminalId'],
    'properties': {
      'terminalId': {
        'type': 'string'
      }
    },
    'title': 'PaymentSensePacTerminalIdParameters'
  },
  'PdfStepProperties': {
    'type': 'object',
    'required': ['targetObject', 'textTemplate'],
    'properties': {
      'locale': {
        'type': 'string',
        'minLength': 2,
        'maxLength': 2
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'textTemplate': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    },
    'title': 'PdfStepProperties'
  },
  'Plugin': {
    'type': 'object',
    'properties': {
      'actions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'checksum': {
        'type': 'string'
      },
      'className': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'size': {
        'type': 'integer',
        'format': 'int32'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'Plugin'
  },
  'PluginExport': {
    'type': 'object',
    'properties': {
      'actions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'checksum': {
        'type': 'string'
      },
      'className': {
        'type': 'string'
      },
      'jar': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'size': {
        'type': 'integer',
        'format': 'int32'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'PluginExport'
  },
  'PluginProperties': {
    'type': 'object',
    'required': ['action', 'pluginName'],
    'properties': {
      'action': {
        'type': 'string'
      },
      'input': {
        'type': 'object'
      },
      'output': {
        'type': 'object'
      },
      'pluginCredentialName': {
        'type': 'string'
      },
      'pluginName': {
        'type': 'string'
      },
      'pluginSettingName': {
        'type': 'string'
      }
    },
    'title': 'PluginProperties'
  },
  'PluginRequest': {
    'type': 'object',
    'required': ['className', 'name', 'roles', 'status'],
    'properties': {
      'actions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'className': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'PluginRequest'
  },
  'ProcessCredential': {
    'type': 'object',
    'properties': {
      'adminOptions': {
        'type': 'object'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['AWS', 'FLOWY', 'IMAP', 'JDBC', 'JMS', 'JWT', 'KAFKA', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PLUGIN', 'RABBITMQ', 'REST', 'SCRIPT', 'SECURITY', 'SLACK', 'SMTP', 'TWILIO']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'ProcessCredential'
  },
  'ProcessCredentialExport': {
    'type': 'object',
    'properties': {
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['AWS', 'FLOWY', 'IMAP', 'JDBC', 'JMS', 'JWT', 'KAFKA', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PLUGIN', 'RABBITMQ', 'REST', 'SCRIPT', 'SECURITY', 'SLACK', 'SMTP', 'TWILIO']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'ProcessCredentialExport'
  },
  'ProcessCredentialRequest«AwsCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/AwsCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«AwsCredentialValues,Void»'
  },
  'ProcessCredentialRequest«FlowyCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/FlowyCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«FlowyCredentialValues,Void»'
  },
  'ProcessCredentialRequest«ImapCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/ImapCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«ImapCredentialValues,Void»'
  },
  'ProcessCredentialRequest«JdbcCredentialValues,JdbcAdminOptions»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'adminOptions': {
        '$ref': '#/definitions/JdbcAdminOptions'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/JdbcCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«JdbcCredentialValues,JdbcAdminOptions»'
  },
  'ProcessCredentialRequest«JmsCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/JmsCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«JmsCredentialValues,Void»'
  },
  'ProcessCredentialRequest«JwtCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/JwtCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«JwtCredentialValues,Void»'
  },
  'ProcessCredentialRequest«KafkaCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/KafkaCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«KafkaCredentialValues,Void»'
  },
  'ProcessCredentialRequest«Map«string,object»,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'ProcessCredentialRequest«Map«string,object»,Void»'
  },
  'ProcessCredentialRequest«MongodbCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/MongodbCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«MongodbCredentialValues,Void»'
  },
  'ProcessCredentialRequest«PaymentSenseConnectECredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/PaymentSenseConnectECredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«PaymentSenseConnectECredentialValues,Void»'
  },
  'ProcessCredentialRequest«PaymentSensePacCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/PaymentSensePacCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«PaymentSensePacCredentialValues,Void»'
  },
  'ProcessCredentialRequest«RabbitmqCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/RabbitmqCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«RabbitmqCredentialValues,Void»'
  },
  'ProcessCredentialRequest«SecurityCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SecurityCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«SecurityCredentialValues,Void»'
  },
  'ProcessCredentialRequest«SlackCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SlackCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«SlackCredentialValues,Void»'
  },
  'ProcessCredentialRequest«SmtpCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SmtpCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«SmtpCredentialValues,Void»'
  },
  'ProcessCredentialRequest«TwilioCredentialValues,Void»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/TwilioCredentialValues'
      }
    },
    'title': 'ProcessCredentialRequest«TwilioCredentialValues,Void»'
  },
  'ProcessCredentialTest': {
    'type': 'object',
    'properties': {
      'error': {
        'type': 'string'
      },
      'isValid': {
        'type': 'boolean'
      }
    },
    'title': 'ProcessCredentialTest'
  },
  'ProcessCredentialTestRequest«AwsCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/AwsCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«AwsCredentialValues»'
  },
  'ProcessCredentialTestRequest«FlowyCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/FlowyCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«FlowyCredentialValues»'
  },
  'ProcessCredentialTestRequest«ImapCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/ImapCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«ImapCredentialValues»'
  },
  'ProcessCredentialTestRequest«JdbcCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/JdbcCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«JdbcCredentialValues»'
  },
  'ProcessCredentialTestRequest«JmsCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/JmsCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«JmsCredentialValues»'
  },
  'ProcessCredentialTestRequest«JwtCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/JwtCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«JwtCredentialValues»'
  },
  'ProcessCredentialTestRequest«KafkaCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/KafkaCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«KafkaCredentialValues»'
  },
  'ProcessCredentialTestRequest«MongodbCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/MongodbCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«MongodbCredentialValues»'
  },
  'ProcessCredentialTestRequest«PaymentSenseConnectECredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/PaymentSenseConnectECredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«PaymentSenseConnectECredentialValues»'
  },
  'ProcessCredentialTestRequest«PaymentSensePacCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/PaymentSensePacCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«PaymentSensePacCredentialValues»'
  },
  'ProcessCredentialTestRequest«RabbitmqCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/RabbitmqCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«RabbitmqCredentialValues»'
  },
  'ProcessCredentialTestRequest«SlackCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/SlackCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«SlackCredentialValues»'
  },
  'ProcessCredentialTestRequest«SmtpCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/SmtpCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«SmtpCredentialValues»'
  },
  'ProcessCredentialTestRequest«TwilioCredentialValues»': {
    'type': 'object',
    'required': ['values'],
    'properties': {
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        '$ref': '#/definitions/TwilioCredentialValues'
      }
    },
    'title': 'ProcessCredentialTestRequest«TwilioCredentialValues»'
  },
  'ProcessExecution': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'eventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'finishedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'shouldFinishBy': {
        'type': 'string',
        'format': 'date-time'
      },
      'startedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'threadId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'ProcessExecution'
  },
  'ProcessExport': {
    'type': 'object',
    'properties': {
      'comment': {
        'type': 'string'
      },
      'errorsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'isCacheStepLogsEnabled': {
        'type': 'boolean'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isStepLogsEnabled': {
        'type': 'boolean'
      },
      'logsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'maxProcessingDurationInMSec': {
        'type': 'integer',
        'format': 'int32'
      },
      'name': {
        'type': 'string'
      },
      'overallSimultaneousExecutions': {
        'type': 'integer',
        'format': 'int32'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'simultaneousExecutionsPerInstance': {
        'type': 'integer',
        'format': 'int32'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'steps': {
        '$ref': '#/definitions/ProcessSteps'
      },
      'validationRuleName': {
        'type': 'string'
      }
    },
    'title': 'ProcessExport'
  },
  'ProcessRequest': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'steps'],
    'properties': {
      'comment': {
        'type': 'string'
      },
      'errorsTtlInMSec': {
        'type': 'integer',
        'format': 'int64',
        'maximum': 315360000000,
        'exclusiveMaximum': false
      },
      'isCacheStepLogsEnabled': {
        'type': 'boolean'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isStepLogsEnabled': {
        'type': 'boolean'
      },
      'logsTtlInMSec': {
        'type': 'integer',
        'format': 'int64',
        'minimum': 0,
        'maximum': 315360000000,
        'exclusiveMinimum': false,
        'exclusiveMaximum': false
      },
      'maxProcessingDurationInMSec': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 500,
        'exclusiveMinimum': false
      },
      'name': {
        'type': 'string'
      },
      'overallSimultaneousExecutions': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'exclusiveMinimum': false
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'simultaneousExecutionsPerInstance': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'exclusiveMinimum': false
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'steps': {
        '$ref': '#/definitions/ProcessSteps'
      },
      'validationRuleId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'ProcessRequest'
  },
  'ProcessSettingProperties': {
    'type': 'object',
    'required': ['processSettingName', 'targetObject'],
    'properties': {
      'processSettingName': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ProcessSettingProperties'
  },
  'ProcessStatistics': {
    'type': 'object',
    'properties': {
      'eventStatuses': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EventStatusCount'
        }
      },
      'last15MinutesCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'last24HoursCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'last60MinutesCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'todayCount': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'ProcessStatistics'
  },
  'ProcessStepFailure': {
    'type': 'object',
    'properties': {
      'count': {
        'type': 'integer',
        'format': 'int64'
      },
      'exception': {
        'type': 'string'
      },
      'firstOccurrence': {
        'type': 'string',
        'format': 'date-time'
      },
      'lastOccurrence': {
        'type': 'string',
        'format': 'date-time'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'ProcessStepFailure'
  },
  'ProcessStepLog': {
    'type': 'object',
    'properties': {
      'childEventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'debugLog': {
        'type': 'string'
      },
      'eventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'exceptionMessage': {
        'type': 'string'
      },
      'exceptionStackTrace': {
        'type': 'string'
      },
      'finishedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'historyId': {
        'type': 'integer',
        'format': 'int64'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['CAUGHT', 'FAILED', 'SUCCESSFUL']
      },
      'stepId': {
        'type': 'integer',
        'format': 'int32'
      },
      'stepType': {
        'type': 'string',
        'enum': ['CREDENTIAL', 'CSV', 'EMAIL', 'ENCODER', 'EXECUTE_EXTERNAL_COMMAND', 'EXECUTE_PROCESS', 'FOREACH', 'FORMATTING', 'GROOVY', 'IMAGE', 'IMAP', 'JDBC', 'JS', 'JWT', 'LOG', 'MESSAGING', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PDF', 'PLUGIN', 'PROCESS_SETTING', 'QUERY_BUILDER', 'REST', 'S3', 'SECURITY', 'SLACK', 'STORAGE', 'SWITCH', 'TRY_CATCH', 'TWILIO', 'UNSET_VARIABLES', 'USER', 'UUID', 'WHILE', 'ZIP']
      }
    },
    'title': 'ProcessStepLog'
  },
  'ProcessStepLogCache': {
    'type': 'object',
    'properties': {
      'cache': {
        'type': 'object'
      }
    },
    'title': 'ProcessStepLogCache'
  },
  'ProcessSteps': {
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Step«StepProperties»'
        }
      }
    },
    'title': 'ProcessSteps'
  },
  'QueryBuilderStepProperties': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'queryConditions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/QueryCondition'
        }
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'QueryBuilderStepProperties'
  },
  'QueryCondition': {
    'type': 'object',
    'required': ['condition', 'query'],
    'properties': {
      'condition': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      }
    },
    'title': 'QueryCondition'
  },
  'RabbitmqCredentialValues': {
    'type': 'object',
    'required': ['host', 'password', 'port', 'username'],
    'properties': {
      'exchange': {
        'type': 'string'
      },
      'host': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'routingKey': {
        'type': 'string'
      },
      'useSslProtocol': {
        'type': 'boolean'
      },
      'username': {
        'type': 'string'
      },
      'virtualHost': {
        'type': 'string'
      }
    },
    'title': 'RabbitmqCredentialValues'
  },
  'ReadS3StepFields': {
    'type': 'object',
    'required': ['key', 'targetObject'],
    'properties': {
      'key': {
        'type': 'string'
      },
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ReadS3StepFields'
  },
  'RegexTest': {
    'type': 'object',
    'properties': {
      'matches': {
        'type': 'boolean'
      }
    },
    'title': 'RegexTest'
  },
  'RegexTestRequest': {
    'type': 'object',
    'required': ['regex', 'value'],
    'properties': {
      'regex': {
        'type': 'string'
      },
      'value': {
        'type': 'string'
      }
    },
    'title': 'RegexTestRequest'
  },
  'RescaleImageStepFields': {
    'type': 'object',
    'required': ['height', 'width'],
    'properties': {
      'height': {
        'type': 'string'
      },
      'source': {
        'type': 'string'
      },
      'width': {
        'type': 'string'
      }
    },
    'title': 'RescaleImageStepFields'
  },
  'ResourceId': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      }
    },
    'title': 'ResourceId'
  },
  'ResourceIdName': {
    'type': 'object',
    'properties': {
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      }
    },
    'title': 'ResourceIdName'
  },
  'ResourceIdNameProjection': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string'
      }
    },
    'title': 'ResourceIdNameProjection'
  },
  'ResourceImportResult': {
    'type': 'object',
    'properties': {
      'conflicts': {
        'type': 'array',
        'items': {
          'type': 'array',
          'items': {
            'type': 'string'
          }
        }
      },
      'hasPermission': {
        'type': 'boolean'
      },
      'isExist': {
        'type': 'boolean'
      },
      'missedRequiredResources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceName'
        }
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      }
    },
    'title': 'ResourceImportResult'
  },
  'ResourceName': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['GLOBAL_TRANSLATION', 'LIBRARY', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_MESSAGING', 'TRIGGER_REST', 'VALIDATION_RULE']
      }
    },
    'title': 'ResourceName'
  },
  'ResourceRole': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['EDIT', 'USE', 'VIEW']
      },
      'role': {
        '$ref': '#/definitions/Role'
      }
    },
    'title': 'ResourceRole'
  },
  'ResourceRoleExport': {
    'type': 'object',
    'properties': {
      'permissionType': {
        'type': 'string',
        'enum': ['EDIT', 'USE', 'VIEW']
      },
      'role': {
        '$ref': '#/definitions/RoleExport'
      }
    },
    'title': 'ResourceRoleExport'
  },
  'ResourceRolesRequest': {
    'type': 'object',
    'required': ['roles'],
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    },
    'title': 'ResourceRolesRequest'
  },
  'ResourceStatus': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'ResourceStatus'
  },
  'ResourcesExport': {
    'type': 'object',
    'properties': {
      'cronTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerCronExport'
        }
      },
      'globalTranslations': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTranslationExport'
        }
      },
      'libraries': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/LibraryExport'
        }
      },
      'messagingTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerMessagingExport'
        }
      },
      'modules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleExport'
        }
      },
      'plugins': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PluginExport'
        }
      },
      'processCredentials': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessCredentialExport'
        }
      },
      'processes': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessExport'
        }
      },
      'restTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerRestExport'
        }
      },
      'settings': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SettingExport'
        }
      },
      'textTemplates': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTemplateExport'
        }
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ValidationRuleExport'
        }
      }
    },
    'title': 'ResourcesExport'
  },
  'RestStepProperties': {
    'type': 'object',
    'required': ['action', 'restResponseCode', 'targetObject', 'targetUrl'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']
      },
      'body': {
        'type': 'object'
      },
      'bodyScript': {
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'headers': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'queryParams': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'restResponseCode': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'targetUrl': {
        'type': 'string'
      },
      'timeout': {
        'type': 'integer',
        'format': 'int32'
      }
    },
    'title': 'RestStepProperties'
  },
  'Role': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isAssignableToUser': {
        'type': 'boolean'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      }
    },
    'title': 'Role'
  },
  'RoleExport': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      }
    },
    'title': 'RoleExport'
  },
  'RoleIdPermission': {
    'type': 'object',
    'required': ['permissionType', 'roleId'],
    'properties': {
      'permissionType': {
        'type': 'string',
        'enum': ['EDIT', 'USE', 'VIEW']
      },
      'roleId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'RoleIdPermission'
  },
  'RoleRequest': {
    'type': 'object',
    'required': ['name'],
    'properties': {
      'name': {
        'type': 'string'
      }
    },
    'title': 'RoleRequest'
  },
  'RolesStepFields': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'roles': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'RolesStepFields'
  },
  'RuleField': {
    'type': 'object',
    'required': ['dataType', 'isRequired', 'name'],
    'properties': {
      'dataType': {
        'type': 'string',
        'enum': ['ARRAY', 'BOOLEAN', 'DOUBLE', 'FILE_JSON', 'FILE_XML', 'INTEGER', 'LONG', 'NUMBER', 'OBJECT', 'STRING', 'TIMESTAMP']
      },
      'genericSubType': {
        'type': 'string',
        'enum': ['BOOLEAN', 'DOUBLE', 'INTEGER', 'NUMBER', 'OBJECT', 'STRING', 'TIMESTAMP']
      },
      'isRequired': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'rules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleValue'
        }
      },
      'validationRuleId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'RuleField'
  },
  'RuleFieldExport': {
    'type': 'object',
    'properties': {
      'dataType': {
        'type': 'string',
        'enum': ['ARRAY', 'BOOLEAN', 'DOUBLE', 'FILE_JSON', 'FILE_XML', 'INTEGER', 'LONG', 'NUMBER', 'OBJECT', 'STRING', 'TIMESTAMP']
      },
      'genericSubType': {
        'type': 'string',
        'enum': ['BOOLEAN', 'DOUBLE', 'INTEGER', 'NUMBER', 'OBJECT', 'STRING', 'TIMESTAMP']
      },
      'isRequired': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'rules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleValue'
        }
      },
      'validationRuleName': {
        'type': 'string'
      }
    },
    'title': 'RuleFieldExport'
  },
  'RuleValue': {
    'type': 'object',
    'required': ['type', 'value'],
    'properties': {
      'type': {
        'type': 'string',
        'enum': ['MAX_LENGTH', 'MAX_SIZE', 'MAX_VALUE', 'MIN_LENGTH', 'MIN_SIZE', 'MIN_VALUE', 'REGEX']
      },
      'value': {
        'type': 'object'
      }
    },
    'title': 'RuleValue'
  },
  'S3StepFields': {
    'type': 'object',
    'properties': {
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'S3StepFields'
  },
  'S3StepProperties': {
    'type': 'object',
    'required': ['action', 'credentialName', 'fields'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'DELETE', 'LIST', 'READ']
      },
      'credentialName': {
        'type': 'string'
      },
      'fields': {
        '$ref': '#/definitions/S3StepFields'
      }
    },
    'title': 'S3StepProperties'
  },
  'SearchFilters': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'body': {
        'type': 'string'
      },
      'maxResults': {
        'type': 'string'
      },
      'readType': {
        'type': 'string',
        'enum': ['ALL', 'READ', 'UNREAD']
      },
      'recipient': {
        'type': 'string'
      },
      'sender': {
        'type': 'string'
      },
      'subject': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'SearchFilters'
  },
  'SecurityCredentialValues': {
    'type': 'object',
    'properties': {
      'key': {
        'type': 'string'
      },
      'passphrase': {
        'type': 'string'
      }
    },
    'title': 'SecurityCredentialValues'
  },
  'SecurityStepProperties': {
    'type': 'object',
    'required': ['action'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['DECRYPT', 'ENCRYPT']
      },
      'credentialName': {
        'type': 'string'
      },
      'method': {
        'type': 'string',
        'enum': ['AES', 'HMAC_SHA256']
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    },
    'title': 'SecurityStepProperties'
  },
  'SetUserPasswordRequest': {
    'type': 'object',
    'required': ['password', 'registerToken'],
    'properties': {
      'password': {
        'type': 'string'
      },
      'registerToken': {
        'type': 'string'
      }
    },
    'title': 'SetUserPasswordRequest'
  },
  'Setting': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['FRONTEND', 'INSTANCE_ADMIN', 'INSTANCE_PROCESSING', 'PLUGIN', 'PROCESS']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'Setting'
  },
  'SettingExport': {
    'type': 'object',
    'properties': {
      'isSystem': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['FRONTEND', 'INSTANCE_ADMIN', 'INSTANCE_PROCESSING', 'PLUGIN', 'PROCESS']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'SettingExport'
  },
  'SettingRequest«FrontendSetting»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/FrontendSetting'
      }
    },
    'title': 'SettingRequest«FrontendSetting»'
  },
  'SettingRequest«InstanceProcessingSetting»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/InstanceProcessingSetting'
      }
    },
    'title': 'SettingRequest«InstanceProcessingSetting»'
  },
  'SettingRequest«Map«string,object»»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'SettingRequest«Map«string,object»»'
  },
  'SettingRequest«object»': {
    'type': 'object',
    'required': ['name', 'roles', 'status', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'SettingRequest«object»'
  },
  'ShippingDetails': {
    'type': 'object',
    'properties': {
      'address': {
        '$ref': '#/definitions/ShippingDetailsAddress'
      },
      'name': {
        'type': 'string'
      }
    },
    'title': 'ShippingDetails'
  },
  'ShippingDetailsAddress': {
    'type': 'object',
    'properties': {
      'userAddress1': {
        'type': 'string'
      },
      'userAddress2': {
        'type': 'string'
      },
      'userAddress3': {
        'type': 'string'
      },
      'userAddress4': {
        'type': 'string'
      },
      'userCity': {
        'type': 'string'
      },
      'userCountryCode': {
        'type': 'string',
        'pattern': '\\d\\d\\d'
      },
      'userPostcode': {
        'type': 'string'
      },
      'userState': {
        'type': 'string'
      }
    },
    'title': 'ShippingDetailsAddress'
  },
  'SlackCredentialValues': {
    'type': 'object',
    'required': ['channelId', 'token'],
    'properties': {
      'channelId': {
        'type': 'string'
      },
      'token': {
        'type': 'string'
      }
    },
    'title': 'SlackCredentialValues'
  },
  'SlackStepProperties': {
    'type': 'object',
    'required': ['credentialName'],
    'properties': {
      'channelId': {
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'locale': {
        'type': 'string',
        'minLength': 2,
        'maxLength': 2
      },
      'message': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'textTemplate': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    },
    'title': 'SlackStepProperties'
  },
  'SmtpCredentialValues': {
    'type': 'object',
    'required': ['host', 'password', 'port', 'username'],
    'properties': {
      'connectionTimeout': {
        'type': 'integer',
        'format': 'int32'
      },
      'host': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'senderAddress': {
        'type': 'string'
      },
      'timeout': {
        'type': 'integer',
        'format': 'int32'
      },
      'username': {
        'type': 'string'
      },
      'writeTimeout': {
        'type': 'integer',
        'format': 'int32'
      }
    },
    'title': 'SmtpCredentialValues'
  },
  'SourceImageStepFields': {
    'type': 'object',
    'properties': {
      'source': {
        'type': 'string'
      }
    },
    'title': 'SourceImageStepFields'
  },
  'SourceTarget': {
    'type': 'object',
    'required': ['sourceObject', 'targetObject'],
    'properties': {
      'sourceObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'SourceTarget'
  },
  'StartTransaction': {
    'type': 'object',
    'required': ['transactionType'],
    'properties': {
      'amount': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'maximum': 99999999,
        'exclusiveMinimum': false,
        'exclusiveMaximum': false
      },
      'amountCashback': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 0,
        'maximum': 10000,
        'exclusiveMinimum': false,
        'exclusiveMaximum': false
      },
      'amountPreAuthorisedTotal': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'maximum': 99999999,
        'exclusiveMinimum': false,
        'exclusiveMaximum': false
      },
      'authCode': {
        'type': 'string'
      },
      'cardholderNotPresent': {
        'type': 'boolean'
      },
      'currency': {
        'type': 'string',
        'enum': ['EUR', 'GBP']
      },
      'transactionType': {
        'type': 'string',
        'enum': ['ACCOUNT_VERIFICATION', 'COMPLETION', 'DUPLICATE', 'PRE_AUTH', 'REFUND', 'SALE']
      }
    },
    'title': 'StartTransaction'
  },
  'StepProperties': {
    'type': 'object',
    'title': 'StepProperties'
  },
  'Step«StepProperties»': {
    'type': 'object',
    'required': ['name', 'properties', 'type'],
    'properties': {
      'enableCacheLog': {
        'type': 'boolean'
      },
      'enableStepLog': {
        'type': 'boolean'
      },
      'enabled': {
        'type': 'boolean'
      },
      'id': {
        'type': 'integer',
        'format': 'int32',
        'minimum': 1,
        'exclusiveMinimum': false
      },
      'name': {
        'type': 'string'
      },
      'properties': {
        '$ref': '#/definitions/StepProperties'
      },
      'type': {
        'type': 'string',
        'enum': ['CREDENTIAL', 'CSV', 'EMAIL', 'ENCODER', 'EXECUTE_EXTERNAL_COMMAND', 'EXECUTE_PROCESS', 'FOREACH', 'FORMATTING', 'GROOVY', 'IMAGE', 'IMAP', 'JDBC', 'JS', 'JWT', 'LOG', 'MESSAGING', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PDF', 'PLUGIN', 'PROCESS_SETTING', 'QUERY_BUILDER', 'REST', 'S3', 'SECURITY', 'SLACK', 'STORAGE', 'SWITCH', 'TRY_CATCH', 'TWILIO', 'UNSET_VARIABLES', 'USER', 'UUID', 'WHILE', 'ZIP']
      }
    },
    'title': 'Step«StepProperties»'
  },
  'StorageStepProperties': {
    'type': 'object',
    'required': ['action', 'key'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['GET', 'UPSERT']
      },
      'fields': {
        'type': 'object',
        'description': 'GET: GetStorageStepFields, UPSERT: UpsertStorageStepFields'
      },
      'key': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      }
    },
    'title': 'StorageStepProperties'
  },
  'SwitchCondition': {
    'type': 'object',
    'required': ['query', 'steps'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Step«StepProperties»'
        }
      }
    },
    'title': 'SwitchCondition'
  },
  'SwitchStepProperties': {
    'type': 'object',
    'required': ['conditions'],
    'properties': {
      'conditions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SwitchCondition'
        }
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      }
    },
    'title': 'SwitchStepProperties'
  },
  'TargetObjectProperties': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'TargetObjectProperties'
  },
  'Telemetry': {
    'type': 'object',
    'properties': {
      'count': {
        'type': 'integer',
        'format': 'int64'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processName': {
        'type': 'string'
      }
    },
    'title': 'Telemetry'
  },
  'TemplateTranslationRequest': {
    'type': 'object',
    'required': ['language', 'name', 'text'],
    'properties': {
      'language': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'text': {
        'type': 'string'
      }
    },
    'title': 'TemplateTranslationRequest'
  },
  'TextTemplate': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      }
    },
    'title': 'TextTemplate'
  },
  'TextTemplateExport': {
    'type': 'object',
    'properties': {
      'body': {
        'type': 'string'
      },
      'engine': {
        'type': 'string',
        'enum': ['HANDLEBARS', 'MUSTACHE', 'THYMELEAF', 'VELOCITY']
      },
      'exampleVariables': {
        'type': 'object'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'translations': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTranslationExport'
        }
      }
    },
    'title': 'TextTemplateExport'
  },
  'TextTemplateFull': {
    'type': 'object',
    'properties': {
      'body': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'engine': {
        'type': 'string',
        'enum': ['HANDLEBARS', 'MUSTACHE', 'THYMELEAF', 'VELOCITY']
      },
      'exampleVariables': {
        'type': 'object'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      }
    },
    'title': 'TextTemplateFull'
  },
  'TextTemplateRequest': {
    'type': 'object',
    'required': ['body', 'engine', 'name', 'roles'],
    'properties': {
      'body': {
        'type': 'string'
      },
      'engine': {
        'type': 'string',
        'enum': ['HANDLEBARS', 'MUSTACHE', 'THYMELEAF', 'VELOCITY']
      },
      'exampleVariables': {
        'type': 'object'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    },
    'title': 'TextTemplateRequest'
  },
  'TextTranslation': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'language': {
        'type': 'string'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'text': {
        'type': 'string'
      }
    },
    'title': 'TextTranslation'
  },
  'TextTranslationExport': {
    'type': 'object',
    'properties': {
      'language': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'text': {
        'type': 'string'
      }
    },
    'title': 'TextTranslationExport'
  },
  'TranslationRequest': {
    'type': 'object',
    'required': ['language', 'name', 'roles', 'text'],
    'properties': {
      'language': {
        'type': 'string',
        'minLength': 2,
        'maxLength': 2
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'text': {
        'type': 'string'
      }
    },
    'title': 'TranslationRequest'
  },
  'TriggerCron': {
    'type': 'object',
    'properties': {
      'comment': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'triggerCondition': {
        'type': 'string'
      }
    },
    'title': 'TriggerCron'
  },
  'TriggerCronExport': {
    'type': 'object',
    'properties': {
      'comment': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'processName': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'triggerCondition': {
        'type': 'string'
      }
    },
    'title': 'TriggerCronExport'
  },
  'TriggerCronRequest': {
    'type': 'object',
    'required': ['name', 'processId', 'roles', 'status', 'triggerCondition'],
    'properties': {
      'comment': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'triggerCondition': {
        'type': 'string'
      }
    },
    'title': 'TriggerCronRequest'
  },
  'TriggerMessaging': {
    'type': 'object',
    'properties': {
      'comment': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'service': {
        'type': 'string',
        'enum': ['AWS_SQS', 'JMS', 'KAFKA', 'RABBITMQ']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'TriggerMessaging'
  },
  'TriggerMessagingExport': {
    'type': 'object',
    'properties': {
      'comment': {
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'processName': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'service': {
        'type': 'string',
        'enum': ['AWS_SQS', 'JMS', 'KAFKA', 'RABBITMQ']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'TriggerMessagingExport'
  },
  'TriggerMessagingRequest': {
    'type': 'object',
    'required': ['credentialId', 'name', 'processId', 'roles', 'service', 'status'],
    'properties': {
      'comment': {
        'type': 'string'
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'service': {
        'type': 'string',
        'enum': ['AWS_SQS', 'JMS', 'KAFKA', 'RABBITMQ']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'TriggerMessagingRequest'
  },
  'TriggerRest': {
    'type': 'object',
    'properties': {
      'basicAccessAuthentication': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'maxRequestSize': {
        'type': 'integer',
        'format': 'int32'
      },
      'method': {
        'type': 'string',
        'enum': ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'url': {
        'type': 'string'
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerValidationRule'
        }
      }
    },
    'title': 'TriggerRest'
  },
  'TriggerRestExists': {
    'type': 'object',
    'properties': {
      'isExist': {
        'type': 'boolean'
      },
      'triggerId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'TriggerRestExists'
  },
  'TriggerRestExport': {
    'type': 'object',
    'properties': {
      'basicAccessAuthentication': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'maxRequestSize': {
        'type': 'integer',
        'format': 'int32'
      },
      'method': {
        'type': 'string',
        'enum': ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']
      },
      'name': {
        'type': 'string'
      },
      'processName': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'url': {
        'type': 'string'
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerValidationRuleExport'
        }
      }
    },
    'title': 'TriggerRestExport'
  },
  'TriggerRestRequest': {
    'type': 'object',
    'required': ['method', 'name', 'processId', 'roles', 'status', 'url'],
    'properties': {
      'basicAccessAuthentication': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'maxRequestSize': {
        'type': 'integer',
        'format': 'int32',
        'description': 'In bytes'
      },
      'method': {
        'type': 'string',
        'enum': ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']
      },
      'name': {
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'url': {
        'type': 'string'
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerValidationRuleRequest'
        }
      }
    },
    'title': 'TriggerRestRequest'
  },
  'TriggerValidationRule': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'type': {
        'type': 'string',
        'enum': ['BODY', 'HEADERS', 'PATH_VARIABLES', 'QUERY_PARAMS']
      },
      'validationRule': {
        '$ref': '#/definitions/ValidationRule'
      }
    },
    'title': 'TriggerValidationRule'
  },
  'TriggerValidationRuleExport': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['BODY', 'HEADERS', 'PATH_VARIABLES', 'QUERY_PARAMS']
      }
    },
    'title': 'TriggerValidationRuleExport'
  },
  'TriggerValidationRuleRequest': {
    'type': 'object',
    'required': ['type', 'validationRuleId'],
    'properties': {
      'type': {
        'type': 'string',
        'enum': ['BODY', 'HEADERS', 'PATH_VARIABLES', 'QUERY_PARAMS']
      },
      'validationRuleId': {
        'type': 'integer',
        'format': 'int64'
      }
    },
    'title': 'TriggerValidationRuleRequest'
  },
  'TryCatchStepProperties': {
    'type': 'object',
    'required': ['try'],
    'properties': {
      'catch': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ExceptionCatch'
        }
      },
      'finally': {
        '$ref': '#/definitions/NamedSteps'
      },
      'try': {
        '$ref': '#/definitions/NamedSteps'
      }
    },
    'title': 'TryCatchStepProperties'
  },
  'TwilioCredentialValues': {
    'type': 'object',
    'required': ['sid', 'token'],
    'properties': {
      'sid': {
        'type': 'string'
      },
      'token': {
        'type': 'string'
      }
    },
    'title': 'TwilioCredentialValues'
  },
  'TwilioStepProperties': {
    'type': 'object',
    'required': ['credentialName', 'from', 'to'],
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'from': {
        'type': 'string'
      },
      'locale': {
        'type': 'string',
        'minLength': 2,
        'maxLength': 2
      },
      'message': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'textTemplate': {
        'type': 'string'
      },
      'to': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    },
    'title': 'TwilioStepProperties'
  },
  'UnsetVariablesStepProperties': {
    'type': 'object',
    'required': ['variables'],
    'properties': {
      'variables': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    },
    'title': 'UnsetVariablesStepProperties'
  },
  'UpdateCredentialFields': {
    'type': 'object',
    'required': ['name', 'queryName', 'type', 'values'],
    'properties': {
      'name': {
        'type': 'string'
      },
      'queryName': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['AWS', 'FLOWY', 'IMAP', 'JDBC', 'JMS', 'JWT', 'KAFKA', 'MONGODB', 'PAYMENT_SENSE_CONNECT_E', 'PAYMENT_SENSE_PAC', 'PLUGIN', 'RABBITMQ', 'REST', 'SCRIPT', 'SECURITY', 'SLACK', 'SMTP', 'TWILIO']
      },
      'values': {
        'type': 'object'
      }
    },
    'title': 'UpdateCredentialFields'
  },
  'UpdatePasswordRequest': {
    'type': 'object',
    'required': ['newPassword', 'oldPassword'],
    'properties': {
      'newPassword': {
        'type': 'string'
      },
      'oldPassword': {
        'type': 'string'
      }
    },
    'title': 'UpdatePasswordRequest'
  },
  'UpsertStorageStepFields': {
    'type': 'object',
    'required': ['expiresIn', 'value'],
    'properties': {
      'expiresIn': {
        'type': 'string'
      },
      'value': {
        'type': 'string'
      }
    },
    'title': 'UpsertStorageStepFields'
  },
  'User': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'email': {
        'type': 'string'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'User'
  },
  'UserApiKey': {
    'type': 'object',
    'properties': {
      'apiKey': {
        'type': 'string'
      }
    },
    'title': 'UserApiKey'
  },
  'UserAuthToken': {
    'type': 'object',
    'properties': {
      'authToken': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'email': {
        'type': 'string'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'UserAuthToken'
  },
  'UserFull': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'email': {
        'type': 'string'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'lastLogin': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'UserFull'
  },
  'UserIdUserStepFields': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      },
      'userId': {
        'type': 'string'
      }
    },
    'title': 'UserIdUserStepFields'
  },
  'UserNameProjection': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'UserNameProjection'
  },
  'UserRequest': {
    'type': 'object',
    'required': ['email', 'name', 'roles', 'status'],
    'properties': {
      'email': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'uniqueItems': true,
        'items': {
          'type': 'integer',
          'format': 'int64'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    },
    'title': 'UserRequest'
  },
  'UserSettingsRequest': {
    'type': 'object',
    'properties': {
      'settings': {
        'type': 'object'
      }
    },
    'title': 'UserSettingsRequest'
  },
  'UserStepFields': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'UserStepFields'
  },
  'UserStepProperties': {
    'type': 'object',
    'required': ['action', 'fields'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CHECK_EMAIL_AVAILABILITY', 'CREATE_USER', 'GENERATE_API_KEY', 'GET_USERS_BY_ROLES', 'GET_USER_DETAILS', 'GET_USER_ROLES', 'VALIDATE_API_KEY', 'VALIDATE_PASSWORD']
      },
      'fields': {
        '$ref': '#/definitions/UserStepFields'
      }
    },
    'title': 'UserStepProperties'
  },
  'UuidStepProperties': {
    'type': 'object',
    'required': ['targetObject'],
    'properties': {
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'UuidStepProperties'
  },
  'ValidateApiKeyUserStepFields': {
    'type': 'object',
    'required': ['apiKey', 'email', 'targetObject'],
    'properties': {
      'apiKey': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ValidateApiKeyUserStepFields'
  },
  'ValidatePasswordUserStepFields': {
    'type': 'object',
    'required': ['email', 'password', 'targetObject'],
    'properties': {
      'email': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ValidatePasswordUserStepFields'
  },
  'ValidationRule': {
    'type': 'object',
    'properties': {
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleField'
        }
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'includes': {
        'type': 'array',
        'items': {
          'type': 'integer',
          'format': 'int64'
        }
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      }
    },
    'title': 'ValidationRule'
  },
  'ValidationRuleExport': {
    'type': 'object',
    'properties': {
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleFieldExport'
        }
      },
      'includes': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      }
    },
    'title': 'ValidationRuleExport'
  },
  'ValidationRuleRequest': {
    'type': 'object',
    'required': ['fields', 'name', 'roles'],
    'properties': {
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleField'
        }
      },
      'includes': {
        'type': 'array',
        'uniqueItems': true,
        'items': {
          'type': 'integer',
          'format': 'int64'
        }
      },
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    },
    'title': 'ValidationRuleRequest'
  },
  'WhileStepProperties': {
    'type': 'object',
    'required': ['query', 'steps'],
    'properties': {
      'query': {
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['GROOVY', 'JS']
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Step«StepProperties»'
        }
      }
    },
    'title': 'WhileStepProperties'
  },
  'ZipElement': {
    'type': 'object',
    'required': ['content', 'name'],
    'properties': {
      'content': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'path': {
        'type': 'string'
      }
    },
    'title': 'ZipElement'
  },
  'ZipStepProperties': {
    'type': 'object',
    'required': ['action', 'targetObject'],
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['COMPRESS', 'DECOMPRESS']
      },
      'fields': {
        'type': 'object',
        'description': 'COMPRESS: CompressZipFields, DECOMPRESS: DecompressZipFields'
      },
      'targetObject': {
        'type': 'string',
        'pattern': '\\$\\.[\\w.]+'
      }
    },
    'title': 'ZipStepProperties'
  }
}